const Footer = () => {
  return (
    <footer>
    <div className="logo">
          <a href="#home">GREY FRAME STUDIOS</a>
    </div>
    <div className="footer-copyright">
        <p>Copyright © 2022 Grey Frame Studios, All rights reserved.</p>
    </div>
</footer>
  )
}

export default Footer